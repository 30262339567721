import { type KnapsackNavItem, type KsAppClientData } from '@knapsack/types';
import { getTypeFromPathMemo } from '@/utils/routes';

export function getNavItemInfo({
  navItem,
  appClientData: { patternsState, customPagesState },
}: {
  appClientData: KsAppClientData;
  navItem: Pick<KnapsackNavItem, 'path' | 'name'>;
}) {
  const { contentType, contentId } = getTypeFromPathMemo(navItem.path);
  let title = navItem.name || navItem.path;
  switch (contentType) {
    case 'pattern': {
      title = patternsState.patterns?.[contentId]?.title;
      break;
    }
    case 'page': {
      title = customPagesState.pages?.[contentId]?.title;
      break;
    }
  }
  return { title, contentType, contentId };
}
