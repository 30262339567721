import { useEffect } from 'react';

// Handle converting old data size values to Toby size values
export const KsGridSizeToTobyGridSize = ({ size }: { size: string }) => {
  switch (size) {
    case 'xs':
      return 'xsmall';
    case 's':
      return 'small';
    case 'm':
      return 'medium';
    case 'l':
      return 'large';
    case 'full':
      return 'full';
  }
};

export const isElementClipped = ({
  containerId,
  elementId,
}: {
  containerId: string;
  elementId: string;
}): {
  element: Element | null;
  isClipped: boolean;
} => {
  const container = document.getElementById(containerId);
  if (!container) return { isClipped: false, element: null };

  const element = container.querySelector(`[id="${elementId}"]`);
  if (!element) return { isClipped: false, element: null };

  const elementRect = element.getBoundingClientRect();
  const parentRect = container.getBoundingClientRect();
  const parentOverflow = getComputedStyle(container).overflow;

  const isClipped =
    (elementRect.top < parentRect.top ||
      elementRect.bottom > parentRect.bottom ||
      elementRect.left < parentRect.left ||
      elementRect.right > parentRect.right) &&
    parentOverflow !== 'visible';

  return { element, isClipped };
};

export const useScrollClippedElementIntoView = ({
  containerId,
  elementId,
  options,
  disable,
}: {
  containerId: string;
  elementId: string;
  options?: ScrollIntoViewOptions;
  disable?: boolean;
}) => {
  useEffect(() => {
    if (!containerId || !elementId || disable) return;
    const { element, isClipped } = isElementClipped({
      containerId,
      elementId,
    });

    if (element && isClipped) {
      element.scrollIntoView(options);
    }
  }, [containerId, elementId, options, disable]);
};
